// import "@hotwired/turbo-rails"
// import "./../controllers"
import { createApp } from "vue"
import MenuTop from '~/components/menu.top.vue'
import MenuBottom from '~/components/menu.bottom.vue'

// Example: Load Rails libraries in Vite.
//
// import '@rails/ujs'
//
// import Turbolinks from 'turbolinks'
// import ActiveStorage from '@rails/activestorage'
//
// // Import all channels.
// import.meta.globEager('./**/*_channel.js')
//
// Turbolinks.start()
// ActiveStorage.start()

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

window.onload = (event) => {
    createApp(MenuTop).mount('#menu')
    createApp(MenuBottom).mount('#bottom-menu')
}
